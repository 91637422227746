import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import DisplayContentHeader from "./DisplayContentHeader";
import { v4 } from "uuid";
class AuthorsListTemplate extends React.Component {
  render() {
    const { data, title } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className="content">
        {title ? (
          <h2 className="title is-size-3 has-text-weight-semibold">{title}</h2>
        ) : null}
        <ul>
          {posts &&
            posts.map(({ node: post }) => (
              <li key={v4()}>
                <DisplayContentHeader
                  title={post.frontmatter.name}
                  slug={post.fields.slug}
                  weight="normal"
                />
              </li>
            ))}
        </ul>
      </div>
    );
  }
}

AuthorsList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function AuthorsList() {
  return (
    <StaticQuery
      query={graphql`
        query AuthorsListQuery {
          allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___name] }
            filter: {
              frontmatter: { advanced: { templateKey: { eq: "author-post" } } }
            }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  name
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <AuthorsListTemplate data={data} count={count} />
      )}
    />
  );
}
